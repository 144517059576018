import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';
import axios from 'axios';
import { useAuth } from './useAuth';
import defaultAvatarLoadingImage from './images/default-avatar-image.jpg';

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const defaultAvatarImage = '/logo.jpg'; // This will refer to logo.jpg in the public folder

const Navbar = () => {
  const { userId, logout } = useAuth();
  const [cartData, setCartData] = useState({ items: [], total_price: 0 });
  const [avatarImage, setAvatarImage] = useState(defaultAvatarImage);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (userId && token) {
      setIsLoading(true);
      fetchCart(token);
      fetchUserData(userId, token);
    } else {
      setAvatarImage(defaultAvatarImage);
    }
  }, [userId]);

  const fetchCart = async (token) => {
    try {
      const response = await axios.get(`${backendUrl}/cart/read`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setCartData(response.data);
    } catch (error) {
      console.error('Error fetching cart:', error);
    }
  };

  const fetchUserData = async (userId, token) => {
    try {
      const response = await fetch(`${backendUrl}/user/fetch/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const userData = await response.json();
        setUser(userData);
        if (userData.avatar_image) {
          setAvatarImage(userData.avatar_image);
        }
      } else {
        throw new Error('Failed to fetch user data');
      }
    } catch (error) {
      console.error('Failed to fetch user data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <Link to="/" className="navbar-logo">Bunny Bubbles Nails</Link>
      </div>
      <div className="navbar-center">
        <Link to="/shop" className="navbar-link">Shop</Link>
        <Link to="/sizing-guide" className="navbar-link">Sizing Guide</Link>
      </div>
      <div className="navbar-right">
        <div className="cart-dropdown">
          <Link to="/cart" className="navbar-cart">Cart ({cartData.items.length})</Link>
          <div className="cart-content">
            <span>{cartData.items.length} Items</span>
            <span>Subtotal: ${cartData.total_price}</span>
            <Link to="/cart" className="view-cart-btn">View Cart</Link>
          </div>
        </div>
        <div className="user-dropdown">
          {isLoading ? (
            <img alt="Loading Avatar" src={defaultAvatarLoadingImage} className="avatar" />
          ) : (
            <img alt="User Avatar" src={avatarImage} className="avatar" />
          )}
          <div className="user-menu">
            <Link to="/profile" className="user-link">Profile</Link>
            {userId ? (
              <button className="user-link" onClick={logout}>Logout</button>
            ) : (
              <>
                <Link to="/login" className="user-link">Login</Link>
                <Link to="/signup" className="user-link">Signup</Link>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;




