import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom"; // Removed useParams since it's not needed here
import 'daisyui/dist/full.css'; // Ensure DaisyUI styles are included
import './Shop.css';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

function Product({ product }) {
  const isSoldOut = product.quantity_available <= 0; // Ensure this property exists

  return (
    <Link to={`/product/read/${product.product_id}`} className={`card ${isSoldOut ? 'sold-out' : ''}`}>
      <img src={product.image_url} alt={product.name} />
      <div className="card-body">
        <h2 className="card-title">{product.name}</h2>
        {isSoldOut && <p className="sold-out-text">Sold Out</p>}
      </div>
    </Link>
  );
}

const Shop = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${backendUrl}/product/read_all`);
        if (response.data.success) {
          console.log('Products response:', response.data.data);
          setProducts(response.data.data || []);
        } else {
          setError('Failed to fetch products');
          console.error('Failed to fetch products:', response.data.error);
        }
      } catch (error) {
        setError('Error fetching products');
        console.error('Error fetching products:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  if (loading) {
    return (
      <div className="container">
        <div>
          <div className="spinner"></div>
          <p className="loading-message">Loading products...</p>
        </div>
      </div>
    ); // Loading state
  }

  if (error) {
    return <p>{error}</p>; // Error state
  }

  return (
    <div className="container">
      <div className="flex-container">
        {products.length > 0 ? (
          products.map(product => (
            <Product key={product.product_id} product={product} /> // Use product_id here
          ))
        ) : (
          <p>No products available</p> // Handle case when there are no products
        )}
      </div>
    </div>
  );
};

export default Shop;

