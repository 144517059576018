import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import Slider from 'react-slick';
import './Product.css';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Product = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [nailSizeOption, setNailSizeOption] = useState('');
  const [leftHandCustomSize, setLeftHandCustomSize] = useState('');
  const [rightHandCustomSize, setRightHandCustomSize] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${backendUrl}/product/read/${productId}`);
        if (response.data.success) {
          setProduct(response.data.data);
        } else {
          console.error('Failed to fetch product data:', response.data.error);
        }
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };

    if (productId) {
      fetchProduct();
    }

    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, [productId]);

  const handleAddToCart = async () => {
    if (!isLoggedIn) {
      setNotification({ type: 'error', message: 'Please log in or sign up to add items to your cart.' });
      return;
    }

    if (!nailSizeOption) {
      setNotification({ type: 'error', message: 'Please select a nail size option.' });
      return;
    }

    if (product && product.quantity < quantity) {
      setNotification({ type: 'error', message: 'Product is sold out.' });
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const cartItem = {
        product_id: productId,
        quantity,
        nail_size_option_id: nailSizeOption,
        left_hand_custom_size: leftHandCustomSize,
        right_hand_custom_size: rightHandCustomSize,
      };

      const response = await axios.post(`${backendUrl}/cart/add_to_cart`, cartItem, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      setNotification({ type: response.data.message ? 'success' : 'error', message: response.data.message || 'Failed to add product to cart.' });
    } catch (error) {
      setNotification({ type: 'error', message: 'Error adding to cart.' });
    }
  };

  const handleQuantityChange = (e) => {
    const value = Math.max(1, parseInt(e.target.value)); // Ensure quantity is at least 1
    setQuantity(value);
  };

  const handleLeftHandCustomSizeChange = (e) => {
    setLeftHandCustomSize(e.target.value);
  };

  const handleRightHandCustomSizeChange = (e) => {
    setRightHandCustomSize(e.target.value);
  };

  // Loading screen when product data is not available
  if (!product) {
    return (
      <div className="loading-screen">
        <div className="loading-content">
          <FontAwesomeIcon icon={faHeart} className="heart-icon" />
          <p className="loading-text">Loading... Please wait!</p>
        </div>
      </div>
    );
  }

  const { name, description, price, sale_price, image_url, image_url_2, image_url_3, image_url_4 } = product;

  // Collect all images
  const images = [image_url, image_url_2, image_url_3, image_url_4].filter(Boolean);

  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="product-container">
      <h1 className="product-name">{name}</h1>
      {images.length > 1 ? (
        <div className="image-carousel">
          <Slider {...settings}>
            {images.map((img, index) => (
              <div key={index} className="image-wrapper">
                <img src={img} alt={name} className="product-image" />
              </div>
            ))}
          </Slider>
        </div>
      ) : (
        <div className="single-image-wrapper">
          <img src={images[0]} alt={name} className="product-image" />
        </div>
      )}
      <div className="description">
        <p>{description}</p>
        <p>
          Check out a video of the product here: <br />
          <a href="https://www.tiktok.com/@bunnybubblenails" className="video-link">
            <FontAwesomeIcon icon={faHeart} /> Click Here
          </a>
        </p>
      </div>
      <div className="price">
        {sale_price ? (
          <p>
            <span className="original-price">${price}</span>{' '}
            <span className="sale-price">${sale_price}</span>
          </p>
        ) : (
          <p>${price}</p>
        )}
      </div>
      <div className="quantity">
        <label htmlFor="quantity" className="quantity">Quantity:</label>
        <input
          className='numbers'
          type="number"
          id="quantity"
          name="quantity"
          min="1"
          value={quantity}
          onChange={handleQuantityChange}
        />
      </div>
      <label className="nailsizes">
        Nail Size Option:
        <br />
        <br />
        <select value={nailSizeOption} onChange={(e) => setNailSizeOption(e.target.value)}>
          <option className="options" value="">Select an option</option>
          <option value="1">XS</option>
          <option value="2">M</option>
          <option value="3">L</option>
          <option value="4">Custom</option>
        </select>
      </label>

      {nailSizeOption === '4' && (
        <div className="custom-size-inputs">
          <label className='left-hand'>
            Left Hand Custom Size:
            <input
              type="text"
              value={leftHandCustomSize}
              onChange={handleLeftHandCustomSizeChange}
              placeholder="Thumb: 11mm, Index: 9mm, Middle: 10mm, Ring Finger: 10mm, Pinky: 8mm"
            />
          </label>
          <label className="right-hand">
            Right Hand Custom Size:
            <input
              type="text"
              value={rightHandCustomSize}
              onChange={handleRightHandCustomSizeChange}
              placeholder="Thumb: 11mm, Index: 9mm, Middle: 10mm, Ring Finger: 10mm, Pinky: 8mm"
            />
          </label>
        </div>
      )}

      <button className="add-to-cart-btn" onClick={handleAddToCart}>
        Add to Cart
      </button>

      {notification && (
        <div className={`notification ${notification.type}`}>{notification.message}</div>
      )}

      <div>
        <br />
        <p>Don't see your size? Check out our <a href="/sizing-guide" className="size-link">Size Guide</a>.</p>
      </div>
    </div>
  );
};

export default Product;
